import React, { useEffect, useRef, useState } from 'react';
import './Apps.scss';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage';
import appsPlayHeaderIcon from '../../assets/components/AppsIcon/PlayHeaderIcon.png';
import ticketIcon from '../../assets/components/AppsIcon/TicketIcon.svg';
import crownIcon from '../../assets/components/AppsIcon/CrownIcon.svg';
import soonGameIcon from '../../assets/components/AppsIcon/SoonGameIcon.png';
import AppsSoonGame from '../../components/AppsSoonGame/AppsSoonGame';
import AppsGame from '../../components/AppsGame/AppsGame';
import Modal from '../../components/Modal/Modal';
import GameStartModal from '../../modals/GameStartModal/GameStartModal';
import Spin from '../../components/Spin/Spin';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { sendUserInfo } from '../../tools/requests';
import useInternetConnection from '../../hooks/useInternetConnection';

const Apps = () => {
    const dispatch = useDispatch();

    const initData = window.Telegram.WebApp.initData;
    const backend_link = process.env.REACT_APP_BACKEND;
    const frontend_link = process.env.REACT_APP_LINK;
    const userInfo = useSelector((state) => state.common.userInfo);
    const games = useSelector((state) => state.common.games);

    const totalTickets = userInfo.tickets;

    const { isOnline, connectionRestored } = useInternetConnection();

    const [openTicketsModal, setOpenTicketsModal] = useState(true);
    const [openStartGameModal, setOpenStartGameModal] = useState(false);
    const [isGameSelected, setIsGameSelected] = useState(false);
    const [selectedGame, setSelectedGame] = useState(null); // Store the entire game object
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [curEvent, setCurEvent] = useState(null);

    const createIframeLink = (id) => {
        let highscores = JSON.parse(localStorage.getItem('highscores')) || [];

        // Check if the highscore for this game already exists
        let gameHighscore = highscores.find((game) => game.id === id);

        if (!gameHighscore) {
            gameHighscore = { id, highscore: 0 }; // Initialize new game highscore if not found
            highscores.push(gameHighscore);
            localStorage.setItem('highscores', JSON.stringify(highscores));
        }

        const iframeDataObj = {
            front: frontend_link,
            host: backend_link,
            id: id,
            initData: initData,
            balance: Number(userInfo.balance),
            tickets: Number(userInfo.tickets),
            highscore: gameHighscore.highscore,
            cost: selectedGame.cost,
        };
        const result = btoa(JSON.stringify(iframeDataObj));
        return result;
    };

    const iframeRef = useRef(null);

    useEffect(() => {
        if (iframeRef.current && isGameSelected) {
            const iframe = iframeRef.current;

            const onLoad = () => {
                setLoading(false);
            };

            iframe.addEventListener('load', onLoad);

            return () => iframe.removeEventListener('load', onLoad);
        }
    }, [isGameSelected, iframeRef]);

    useEffect(() => {
        const handler = (event) => {
            try {
                if (typeof event.data === 'string') {
                    const jsonObj = JSON.parse(event.data);
                    setMessage(jsonObj);
                    setCurEvent(event);
                }
            } catch (err) {
                console.warn('Invalid message received:', err);
            }
        };

        window.addEventListener('message', handler);

        return () => window.removeEventListener('message', handler);
    }, [games]);

    useEffect(() => {
        if (message) {
            if (message.type === 'endgame') {
                if (message.highscore) {
                    const id = Number(message.id);
                    if (isNaN(id)) return;

                    const foundGame = games.find((game) => game.id === id);
                    if (foundGame) {
                        const normalizedOrigin = curEvent.origin.endsWith('/')
                            ? curEvent.origin
                            : `${curEvent.origin}/`;
                        const normalizedIframe = foundGame.iframe.endsWith('/')
                            ? foundGame.iframe
                            : `${foundGame.iframe}/`;

                        if (normalizedOrigin === normalizedIframe) {
                            const parsedMessage = JSON.parse(curEvent.data);
                        }
                    }

                    let highscores =
                        JSON.parse(localStorage.getItem('highscores')) || [];
                    let gameIndex = highscores.findIndex(
                        (game) => game.id === Number(message.id)
                    );

                    if (gameIndex > -1) {
                        if (
                            message.highscore > highscores[gameIndex].highscore
                        ) {
                            highscores[gameIndex].highscore = message.highscore;
                        }
                    } else {
                        highscores.push({
                            id: Number(message.id),
                            highscore: message.highscore,
                        });
                    }

                    localStorage.setItem(
                        'highscores',
                        JSON.stringify(highscores)
                    );
                }
            } else if (message.type === 'close') {
                sendUserInfo(initData, null, dispatch).then(() => {
                    setIsGameSelected(false);
                    setSelectedGame(null);
                });
            } else if (message.type === 'error') {
                setIsGameSelected(false);
                setSelectedGame(null);
                console.warn('Some error during loading game');
                toast.error('Some problems during loading game');
            }
        }
    }, [message]);

    return (
        <Wrapper>
            <div className="apps-container">
                <div className="apps-games-scroller">
                    <div className="apps-header-container">
                        <SuspenseImage
                            className="apps-icon"
                            src={appsPlayHeaderIcon}
                            alt="user avatar"
                            width="10rem"
                            height="11rem"
                            customWrapperStyle={{ padding: '1rem 0 1rem 1rem' }}
                        />
                        <div className="apps-data">
                            <span className="apps-name">Play and WIN</span>
                            <div className="apps-info-block">
                                <span className="apps-info">
                                    Dive into exciting games and unlock endless
                                    fun while earning RIGEL.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="apps-tickets-wrap">
                        <div className="apps-ticket-info">
                            <span className="apps-ticket-icon">
                                <SuspenseImage
                                    width="2.5rem"
                                    height="2.5rem"
                                    src={ticketIcon}
                                    alt="ticket icon"
                                />
                            </span>
                            <span className="apps-ticket-count">
                                {totalTickets}
                            </span>
                            <span className="apps-ticket-name">Tickets</span>
                        </div>
                    </div>
                    <div className="apps-content-wrapper">
                        <div className="apps-ticket-header-title">
                            <span className="apps-title">
                                Trending This Week
                            </span>
                        </div>

                        <div className="apps-active-game-wrapper">
                            {games.map((item) => {
                                let highscores =
                                    JSON.parse(
                                        localStorage.getItem('highscores')
                                    ) || [];
                                let gameHighscore = highscores.find(
                                    (game) => game.id === item.id
                                );
                                let highscore = gameHighscore
                                    ? gameHighscore.highscore
                                    : 0;

                                return (
                                    <AppsGame
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        gamePreviewIcon={item.preview}
                                        cost={item.cost}
                                        isNew={item.is_new}
                                        highscore={highscore}
                                        setOpenStartGameModal={() =>
                                            setOpenStartGameModal(true)
                                        }
                                        setSelectedGame={() =>
                                            setSelectedGame(item)
                                        } // Store the full game object
                                    />
                                );
                            })}
                            <AppsSoonGame
                                gameIcon={soonGameIcon}
                                ticketIcon={ticketIcon}
                                crownIcon={crownIcon}
                            />
                            <AppsSoonGame
                                gameIcon={soonGameIcon}
                                ticketIcon={ticketIcon}
                                crownIcon={crownIcon}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={`apps-iframe-container ${
                        isGameSelected ? 'fullscreen' : ''
                    }`}
                    style={{ zIndex: '200 !important' }}
                >
                    <div className="apps-iframe-relative-wrapper">
                        {loading && <Spin className="spin-overlay" />}
                        {isGameSelected && selectedGame && isOnline && (
                            <iframe
                                ref={iframeRef}
                                title={selectedGame.title}
                                className="apps-iframe"
                                src={`${
                                    selectedGame.iframe
                                }?data=${createIframeLink(selectedGame.id)}`}
                                loading="lazy"
                                allow="autoplay; accelerometer; fullscreen; geolocation; gyroscope; microphone; camera; midi;"
                                referrerPolicy="unsafe-url"
                                aria-hidden="true"
                                mozallowfullscreen="true"
                                sandbox="allow-scripts allow-modals allow-same-origin"
                                z-index="200 !important"
                                style={{
                                    zIndex: '200 !important',
                                    willChange: 'unset !important',
                                    opacity: '1 !important',
                                    transition: 'unset !important',
                                }}
                            >
                                You need to use newer system.
                            </iframe>
                        )}
                    </div>
                </div>
                {/*<Popup
                    title="Buy Tickets"
                    className="payment-popup"
                    open={openTicketsModal}
                    onClose={() => {
                        setOpenTicketsModal(false);
                    }}
                >
                    <BuyNewTicketsModal
                        onClose={() => {
                            setOpenTicketsModal(false);
                        }}
                    />
                </Popup>*/}
            </div>
            <Modal
                className="common-modal"
                open={openStartGameModal}
                onClose={() => setOpenStartGameModal(false)}
                closeBtnDisabled={true}
            >
                {selectedGame && (
                    <GameStartModal
                        totalTickets={totalTickets}
                        title={selectedGame.name}
                        img={selectedGame.icon}
                        ticketCost={selectedGame.cost}
                        setIsGameSelected={() => setIsGameSelected(true)}
                        onClose={() => setOpenStartGameModal(false)}
                    />
                )}
            </Modal>
        </Wrapper>
    );
};

export default Apps;
