import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useTimeLeft from '../../hooks/useTimeLeft';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import Button from '../../components/Button/Button';
import LevelIcon from '../../assets/components/BoostIcon/Level.svg';
import './BoostProductItem.scss';

const BoostProductItem = ({
    id,
    imageSrc,
    altText,
    title,
    data,
    handleModal,
    classname,
    active,
    validUntil,
    boostRate,
}) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const timeLeft = useTimeLeft(validUntil);
    useEffect(() => {
        setIsDisabled(active);
    }, [active]);

    return isDisabled ? (
        <div
            className={`boost-product-upgrade-container ${
                classname ? classname : ''
            } ${active ? 'product-active' : ''}`}
            onClick={() => {}}
        >
            <div className="inner-children">
                <div className="info-container">
                    <div className="img-container">
                        <SuspenseImage
                            className="img-lvl"
                            src={LevelIcon}
                            alt="level icon"
                            width="40%"
                            height="30%"
                            customWrapperStyle={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                            }}
                        />
                        <SuspenseImage
                            className={`img`}
                            src={imageSrc}
                            alt={altText}
                            width="5rem"
                            height="5rem"
                        />
                    </div>
                    <div className="inner-info">
                        <div className="product-upgrade-info">
                            <div className="title">
                                <label className="upgrade-title">
                                    {title || <Skeleton />}
                                </label>
                            </div>
                            <div className="info">
                                <span
                                    className={`product-upgrade-data ${
                                        active
                                            ? 'product-upgrade-data-glowing-text'
                                            : ''
                                    }`}
                                >
                                    {data || <Skeleton />}
                                </span>
                                {boostRate && (
                                    <span className="product-upgrade-rate">
                                        {`x${boostRate}` || boostRate || (
                                            <Skeleton />
                                        )}
                                    </span>
                                )}
                                {active && (
                                    <span className="product-upgrade-time">
                                        {timeLeft === '0'
                                            ? 'finishing...'
                                            : timeLeft || <Skeleton />}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-upgrade-btn-container">
                    <div className="product-upgrade-holder">
                        <div className="product-upgrade-level-holder"></div>
                    </div>
                    {!active && (
                        <div className="product-upgrade">
                            <FontAwesomeIcon
                                className="product-upgrade-icon"
                                icon={faChevronRight}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <Button
            className={`boost-product-upgrade-container ${
                classname ? classname : ''
            } ${active ? 'product-active' : ''}`}
            onClick={active ? () => {} : () => handleModal(id)}
        >
            <div className="info-container">
                <div className="img-container">
                    <SuspenseImage
                        className="img-lvl"
                        src={LevelIcon}
                        alt="level icon"
                        width="40%"
                        height="30%"
                        customWrapperStyle={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                        }}
                    />
                    <SuspenseImage
                        className={`img`}
                        src={imageSrc}
                        alt={altText}
                        width="5rem"
                        height="5rem"
                    />
                </div>

                <div className="inner-info">
                    <div className="product-upgrade-info">
                        <div className="title">
                            <label className="product-upgrade-title">
                                {title || <Skeleton />}
                            </label>
                        </div>
                        <div className="info">
                            <span
                                className={`product-upgrade-data ${
                                    active
                                        ? 'product-upgrade-data-glowing-text'
                                        : ''
                                }`}
                            >
                                {data || <Skeleton />}
                            </span>
                            {boostRate && (
                                <span className="product-upgrade-rate">
                                    {boostRate || <Skeleton />}
                                </span>
                            )}
                            {active && (
                                <span className="product-upgrade-time">
                                    {timeLeft === '0'
                                        ? 'finishing...'
                                        : timeLeft || <Skeleton />}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-upgrade-btn-container">
                <div className="product-upgrade-holder">
                    <div className="product-upgrade-level-holder"></div>
                </div>
                {!active && (
                    <div className="product-upgrade">
                        <FontAwesomeIcon
                            className="product-upgrade-icon"
                            icon={faChevronRight}
                        />
                    </div>
                )}
            </div>
        </Button>
    );
};

export default BoostProductItem;
