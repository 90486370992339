import { useState, useEffect } from 'react';

const useInternetConnection = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [connectionRestored, setConnectionRestored] = useState(false);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setConnectionRestored(true);
            setTimeout(() => setConnectionRestored(false), 2000);
            setTimeout(() => window.location.reload(), 3000);
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        const checkConnection = () => {
            setIsOnline(navigator.onLine);
        };

        checkConnection();

        const interval = setInterval(checkConnection, 1000);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            clearInterval(interval);
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return { isOnline, connectionRestored };
};

export default useInternetConnection;
