import React, { useEffect, useState } from 'react';
import './BoostItem.scss';
import Button from '../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import starWhiteIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import requiredIcon from '../../assets/components/ReqiredIcon/RequiredIcon.svg';
import SuspenseImage from '../SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';

const BoostItem = ({
    id,
    imageSrc,
    altText,
    title,
    claimTime,
    currentReferrals,
    requiredReferrals,
    cost,
    upgradeData,
    upgradeAdditionalInfo,
    upgradeAdditionalLevel,
    handleModal,
    commingSoon,
    classname,
    loading,
    disabled,
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isEnoughReferrals, setIsEnoughReferrals] = useState(false);

    useEffect(() => {
        if (currentReferrals >= requiredReferrals) {
            setIsEnoughReferrals(true);
        } else {
            setIsEnoughReferrals(false);
            setIsDisabled(true);
        }
    }, [currentReferrals, requiredReferrals]);

    useEffect(() => {
        if (disabled === true) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false)
        }
    }, [disabled]);

    if (isDisabled === true) {
        return (
            <div
                className={`boost-upgrade-container ${
                    classname ? classname : ''
                } ${isDisabled ? 'disabledItem' : ''}`}
                onClick={handleModal(id)}
                style={
                    isDisabled
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                }
            >
                <div className="inner-children">
                    <div className="info-container">
                        <div className="img-container">
                            <SuspenseImage
                                className={`img ${
                                    !isEnoughReferrals && 'blur'
                                }`}
                                src={imageSrc}
                                alt={altText}
                                width="5rem"
                                height="5rem"
                            />
                            {!isEnoughReferrals && (
                                <SuspenseImage
                                    className="locked-img"
                                    src={requiredIcon}
                                    alt={altText}
                                    width="5rem"
                                    height="5rem"
                                    customWrapperStyle={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '1.2rem',
                                        zIndex: '2',
                                    }}
                                />
                            )}
                        </div>
                        <div className="inner-info">
                            <div className="upgrade-info">
                                <div className="title">
                                    <label className="upgrade-title">
                                        {title || <Skeleton />}
                                    </label>
                                </div>
                                <div className="info">
                                    <span className="upgrade-data">
                                        {claimTime || <Skeleton />}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="upgrade-btn-container">
                        <div className="upgrade-holder">
                            <div className="upgrade-level-holder">
                                {upgradeAdditionalLevel && (
                                    <div
                                        className={`${
                                            upgradeAdditionalLevel ===
                                            'Max Level'
                                                ? 'upgrade-additional-level glow'
                                                : 'upgrade-additional-level'
                                        }`}
                                    >
                                        {upgradeAdditionalLevel || <Skeleton />}
                                    </div>
                                )}
                            </div>
                            {cost && (
                                <div className="upgrade-coin-holder">
                                    {isEnoughReferrals && (
                                        <>
                                            <span className="coin-img">
                                                <SuspenseImage
                                                    src={starWhiteIcon}
                                                    alt="star icon"
                                                    width="1.5rem"
                                                    height="1.5rem"
                                                />
                                            </span>
                                            <span className="cost-data">
                                                {cost || <Skeleton />}
                                            </span>
                                        </>
                                    )}
                                    {!isEnoughReferrals && (
                                        <span className="required-refs">
                                            {requiredReferrals ? (
                                                `Invite ${requiredReferrals} Friends`
                                            ) : (
                                                <Skeleton />
                                            )}
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            className={`upgrade ${
                                isDisabled ? 'hidden-icon' : ''
                            }`}
                        >
                            <FontAwesomeIcon
                                className="upgrade-icon"
                                icon={faChevronRight}
                            />
                        </div>
                    </div>
                    {commingSoon && (
                        <div className="scroll-anim-container">
                            <div className="scroll-anim-wrapper">
                                <div className="scroll-anim-item">
                                    Coming Soon
                                </div>
                                <div className="scroll-anim-item">
                                    Coming Soon
                                </div>
                                <div className="scroll-anim-item">
                                    Coming Soon
                                </div>
                                <div className="scroll-anim-item">
                                    Coming Soon
                                </div>
                                <div className="scroll-anim-item">
                                    Coming Soon
                                </div>
                                <div className="scroll-anim-item">
                                    Coming Soon
                                </div>
                            </div>
                            <div className="scroll-anim-fade-element"></div>
                        </div>
                    )}
                </div>
                {loading && (
                    <div className="loader-mask">
                        <span className="loader"></span>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <Button
                className={`boost-upgrade-container ${
                    classname ? classname : ''
                }`}
                onClick={handleModal(id)}
            >
                <div className="info-container">
                    <div className="img-container">
                        <SuspenseImage
                            className={`img ${!isEnoughReferrals && 'blur'}`}
                            src={imageSrc}
                            alt={altText}
                            width="5rem"
                            height="5rem"
                        />
                        {!isEnoughReferrals && (
                            <SuspenseImage
                                className="locked-img"
                                src={requiredIcon}
                                alt={altText}
                                width="5rem"
                                height="5rem"
                                customWrapperStyle={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '1.2rem',
                                    zIndex: '2',
                                }}
                            />
                        )}
                    </div>

                    <div className="inner-info">
                        <div className="upgrade-info">
                            <div className="title">
                                <label className="upgrade-title">
                                    {title || <Skeleton />}
                                </label>
                            </div>
                            <div className="info">
                                <span className="upgrade-data">
                                    {claimTime || <Skeleton />}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="upgrade-btn-container">
                    <div className="upgrade-holder">
                        <div className="upgrade-level-holder">
                            {upgradeAdditionalLevel && (
                                <div
                                    className={`${
                                        upgradeAdditionalLevel === 'Max Level'
                                            ? 'upgrade-additional-level glow'
                                            : 'upgrade-additional-level'
                                    }`}
                                >
                                    {upgradeAdditionalLevel || <Skeleton />}
                                </div>
                            )}
                        </div>
                        {cost && (
                            <div className="upgrade-coin-holder">
                                {isEnoughReferrals && (
                                    <>
                                        <span className="coin-img">
                                            <SuspenseImage
                                                src={starWhiteIcon}
                                                alt="star icon"
                                                width="1.5rem"
                                                height="1.5rem"
                                            />
                                        </span>
                                        <span className="cost-data">
                                            {cost || <Skeleton />}
                                        </span>
                                    </>
                                )}
                                {!isEnoughReferrals && (
                                    <span className="required-refs">
                                        {requiredReferrals ? (
                                            `Invite ${requiredReferrals} Friends`
                                        ) : (
                                            <Skeleton />
                                        )}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    <div
                        className={`upgrade ${isDisabled ? 'hidden-icon' : ''}`}
                    >
                        <FontAwesomeIcon
                            className="upgrade-icon"
                            icon={faChevronRight}
                        />
                    </div>
                </div>
                {commingSoon && (
                    <div className="scroll-anim-container">
                        <div className="scroll-anim-wrapper">
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                        </div>
                        <div className="scroll-anim-fade-element"></div>
                    </div>
                )}
                {loading && (
                    <div className="loader-mask">
                        <span className="loader"></span>
                    </div>
                )}
            </Button>
        );
    }
};

export default BoostItem;
