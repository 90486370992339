import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchReferralData, sendStorageRemind } from '../../tools/requests';
import { setRemindedFriends } from '../../store/commonReducer';
import toast from 'react-hot-toast';
import Button from '../../components/Button/Button';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import StarIcon from '../../assets/components/StarIcon/RigelWhite.svg';
import AnonymousIcon from '../../assets/components/Anonymous/AnonymousIcon.png';
import friendsIcon from '../../assets/components/FriendsIcon/Friends_Ill.svg';
import './Friends.scss';
import copyIcon from '../../assets/components/CopyIcon/Copy.svg';
import SuspenseImage from '../../components/SuspenseImage/SuspenseImage';
import Skeleton from 'react-loading-skeleton';

const coinname = process.env.REACT_APP_COIN_NAME;
const appname = process.env.REACT_APP_NAME;
const telegram_url = process.env.REACT_APP_TELEGRAM_BOT_URL;

const Friends = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.common.userInfo);
    const currentPage = useSelector((state) => state.common.currentPage);
    const totalPages = useSelector((state) => state.common.totalPages);
    const referralTokens = useSelector((state) => state.common.referralTokens);
    const friends = useSelector((state) => state.common.friends);
    const linkNext = useSelector((state) => state.common.linkNext);
    const totalFriends = useSelector((state) => state.common.totalFriends);
    const remindedFriends = useSelector(
        (state) => state.common.remindedFriends
    );

    const [localRemindedFriends, setLocalRemindedFriends] = useState([]);
    const [hasScrolled, setHasScrolled] = useState(false);

    const initData = window.Telegram.WebApp.initData;
    const referal_id = userInfo.referral_id ? userInfo.referral_id : null;

    const observer = useRef();
    const lastFriendElementRef = useRef(null);
    const friendsContainerRef = useRef(null);

    const setupObserver = useCallback(() => {
        if (observer.current) observer.current.disconnect();

        if (currentPage < totalPages && lastFriendElementRef.current) {
            observer.current = new IntersectionObserver(async (entries) => {
                if (entries[0].isIntersecting) {
                    if (linkNext === null) {
                        //toast.success('you are at the end');
                    } else {
                        await fetchReferralData(
                            currentPage + 1,
                            initData,
                            dispatch,
                            true,
                            friends
                        );
                    }
                }
            });

            observer.current.observe(lastFriendElementRef.current);
        }
    }, [dispatch]);

    const handleInviteClick = () => {
        const url = `${telegram_url}?startapp=${referal_id}`;
        const text = `Join me on ${coinname} farm at ${appname} app!`;

        const deepLink = `https://t.me/share/url?url=${encodeURIComponent(
            url
        )}&text=${encodeURIComponent(text)}`;

        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink(deepLink);
        }
    };

    const copyInviteLink = () => {
        const url = `${telegram_url}?startapp=${referal_id}`;

        navigator.clipboard.writeText(url).then(
            () => {
                toast('Referral link copied');
            },
            (err) => {
                toast.error('Failed to copy invite link.');
                console.error('Failed to copy text: ', err);
            }
        );
    };

    const handleRemindClick = (chatId) => {
        const now = Date.now();
        const expiredAt = now + 60 * 60 * 1000; // 1 hour from now
        const remindedFriend = { chatId, remindedAt: now, expiredAt };

        // Save to local storage
        const storedRemindedFriends =
            JSON.parse(localStorage.getItem('remindedFriends')) || [];
        storedRemindedFriends.push(remindedFriend);
        localStorage.setItem(
            'remindedFriends',
            JSON.stringify(storedRemindedFriends)
        );

        // Dispatch to Redux store
        sendStorageRemind(chatId, initData);
        dispatch(setRemindedFriends(remindedFriend));
        setLocalRemindedFriends((prev) => [...prev, chatId]);
    };

    const canRemindFriend = (chatId) => {
        const now = Date.now();
        const isFriend = friends.some((item) => item.chat_id === chatId);
        const isRemindedFriend = remindedFriends.find(
            (item) => item.chatId === chatId
        );
        const isLocalRemindedFriend = localRemindedFriends.includes(chatId);

        if (isFriend && !isLocalRemindedFriend) {
            if (!isRemindedFriend || isRemindedFriend.expiredAt < now) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        if (!hasScrolled && friendsContainerRef.current) {
            friendsContainerRef.current.scrollTop = 0;
            setHasScrolled(true);
        }
    }, [hasScrolled]);

    return (
        <Wrapper>
            <div
                className="friends-container"
            >
                <div className="referral-card">
                    <div className="friends-info">
                        <SuspenseImage
                            className="friends-icon"
                            src={friendsIcon}
                            alt="user avatar"
                            width="10rem"
                            height="10rem"
                        />
                        <div className="friends-data">
                            <div className="referral-label-container">
                                <label className="referral-title">
                                    {(totalFriends === 0
                                        ? 'No '
                                        : `${totalFriends} `) || (<Skeleton />)}
                                    Friends
                                </label>
                                <div className="user-coins">
                                    <SuspenseImage
                                        className="diamond"
                                        src={StarIcon}
                                        alt="diamond icon"
                                        style={{ width: '2rem' }}
                                        width="2rem"
                                    />
                                    <span className="coins">
                                        {referralTokens || <Skeleton />}
                                    </span>
                                </div>
                            </div>
                            <div className="referral-description-container">
                                <span className="referral-description">
                                    Every time your friend claims coins you get
                                    15% cashback. And 5% every time their
                                    referrals claim it.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <label className="friend-title">My Friends</label>
                {friends.length > 0 ? (
                    <div className="friend-list">
                        <div
                            className="scroll-wrapper"
                            ref={friendsContainerRef}
                        >
                            {friends.map((friend, index) => (
                                <div
                                    className="friend-info"
                                    key={friend.chat_id}
                                    ref={
                                        index === friends.length - 1
                                            ? (node) => {
                                                  lastFriendElementRef.current =
                                                      node;
                                                  if (linkNext !== null) {
                                                      setupObserver();
                                                  }
                                              }
                                            : null
                                    }
                                >
                                    <div className="friend">
                                        <SuspenseImage
                                            className="friend-img"
                                            src={
                                                friend.photo_url ||
                                                AnonymousIcon
                                            }
                                            alt={`${friend.first_name}'s avatar`}
                                            width="4.5rem"
                                            height="4.5rem"
                                        />
                                        <div className="info">
                                            <span className="name">
                                                {friend.first_name || (
                                                    <Skeleton />
                                                )}
                                            </span>
                                            <div className="amount">
                                                <SuspenseImage
                                                    className="amount-img"
                                                    src={StarIcon}
                                                    alt="diamond icon"
                                                    width="2rem"
                                                    height="2rem"
                                                />
                                                <span className="coins">
                                                    {(parseFloat(
                                                        friend.balance
                                                    ).toFixed(6))
                                                    || (<Skeleton />)}
                                                </span>
                                            </div>
                                        </div>
                                        {friend.claim &&
                                            friend.chat_id &&
                                            friend.claim.can_be_storaged &&
                                            canRemindFriend(friend.chat_id) && (
                                                <div className="remind">
                                                    <Button
                                                        className="friend-remind-btn glowing"
                                                        onClick={() =>
                                                            handleRemindClick(
                                                                friend.chat_id
                                                            )
                                                        }
                                                    >
                                                        Remind
                                                    </Button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="no-referrals-container">
                        <div className="no-referrals">
                            <span className="title">You have no friends</span>
                            <span className="info">
                                Invite your friends and colleagues in order to
                                get bonuses and complete quests together
                            </span>
                            <div className="no-referrals-invite-card">
                                <Button
                                    className="invite-btn"
                                    onClick={handleInviteClick}
                                >
                                    Invite a friend
                                    <div className="no-referrals-friend-notification"></div>
                                </Button>

                                <Button
                                    className="copy-btn"
                                    onClick={copyInviteLink}
                                >
                                    <SuspenseImage
                                        src={copyIcon}
                                        alt="copy icon"
                                        width="2.4rem"
                                        height="2.4rem"
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="friends-footer">
                    {friends.length > 0 && (
                        <div className="invite-card">
                            <Button
                                className="invite-btn"
                                onClick={handleInviteClick}
                            >
                                Invite a friend
                            </Button>
                            <Button
                                className="copy-btn"
                                onClick={copyInviteLink}
                            >
                                <SuspenseImage
                                    src={copyIcon}
                                    alt="copy icon"
                                    width="2.4rem"
                                    height="2.4rem"
                                />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Wrapper>
    );
};

export default Friends;
