import React from 'react';
import './AppsSoonGame.scss';
import SuspenseImage from '../SuspenseImage/SuspenseImage';

const AppsSoonGame = ({ gameIcon, ticketIcon, crownIcon }) => {
    return (
        <div className="apps-soon-game">
            <div className="apps-game-wrapper">
                <div className="apps-game-img-block">
                    <SuspenseImage
                        src={gameIcon}
                        className="apps-game-img"
                        alt="soon game icon"
                        width="100%"
                        height="17rem"
                        customWrapperStyle={{
                            width: '100%',
                            height: '100%',
                        }}
                    />

                    <div className="scroll-anim-container">
                        <div className="scroll-anim-wrapper">
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                            <div className="scroll-anim-item">Coming Soon</div>
                        </div>
                        <div className="scroll-anim-fade-element"></div>
                    </div>
                </div>
                <div className="apps-game-info">
                    <span className="apps-game-title">Hidden Game</span>
                    <span className="apps-game-price">
                        <SuspenseImage
                            src={crownIcon}
                            className="apps-crown-icon"
                            alt="crown icon"
                            width="1.6rem"
                            height="1.6rem"
                        />
                        <span className="apps-price">999.9999</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default AppsSoonGame;
