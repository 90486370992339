import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import ticketIcon from '../../assets/components/AppsIcon/TicketIcon.svg';
import './GameStartModal.scss';

const GameStartModal = ({ title, img, totalTickets, ticketCost, setIsGameSelected, onClose }) => {
    const dispatch = useDispatch();

    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        setIsGameSelected();
        onClose();
    };

    return (
        <div className="game-start-modal-container">
            <div className="gsm-ticket-balance-wrapper">
                <span className="gsm-ticket-span">
                    <img className="gsm-ticket-image" src={ticketIcon}></img>
                    <span className="gsm-ticket-count">{totalTickets}</span>
                    <span className="gsm-ticket-name">Tickets</span>
                </span>
            </div>
            <div className="gsm-question">
                <span className="gsm-question-left-part">Play for</span>
                <div className="gsm-question-middle-part">
                    <img className="gsm-question-image" src={ticketIcon}></img>
                    <span className="gsm-question-count">-{ticketCost}</span>
                    <span className="gsm-question-name">Ticket</span>
                </div>
                <span className="gsm-question-right-part">?</span>
            </div>
            <div className="gsm-game-preview">
                <img className="gsm-game-image" src={img}></img>
                <span className="gsm-game-name">{title}</span>
            </div>
            <div className="gsm-confirm-btns">
                <Button
                    className="gsm-btn gsm-btn-cancel select"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    className={`gsm-btn gsm-btn-confirm select ${
                        totalTickets >= ticketCost ? 'glowing' : ''
                    }`}
                    onClick={handleConfirm}
                    disabled={totalTickets < ticketCost ? true : false}
                >
                    Play
                </Button>
            </div>
        </div>
    );
};

export default GameStartModal;
