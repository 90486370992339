import React from 'react';
import './Spin.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Spin = () => {
    return (
        <section
            className="spin-wrapper"
            style={{
                zIndex: '1001 !important',
                willChange: 'unset !important',
                opacity: '1 !important',
                transition: 'unset !important',
            }}
        >
            <div className="half-transparent-layout"></div>
            <div className="area">
                <ul className="stars">
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faStar} />
                    </li>
                </ul>
            </div>
            <div className="background"></div>
            <span className="loader"></span>
            <div className="loading-text">{'Loading...'}</div>
        </section>
    );
};

export default Spin;
